import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { getCSS } from '../../functions/css.function';
import { useDevice } from '../../hook/useDevice.hook';
import { useNotification } from '../../hook/useNotification.hook';
import { useRootStore } from '../../hook/useRootStore.hook';
import { useTranslation } from '../../hook/useTranslation.hook';
import { Debug } from '../debug/debug.component';
import { Elevate } from '../elevate/elevate.component';
import { Header, HeaderType } from '../header/header.component';
import { NavDesktop, NavDesktopType, NavMobileTop } from '../nav/nav.component';
import { Write } from '../write/write.component';
import './main.component.scss';

export declare namespace MainType {
  type Props = {
    handleEvent: { selected: (value: string) => void };
    config?: {
      navigation?: {
        mode?: NavDesktopType.Config.Mode;
        logo?: NavDesktopType.Config.Logo;
      };
      header?: {
        logo?: HeaderType.Config.Logo;
        shortcut?: HeaderType.Config.Shortcut[];
        extra?: HeaderType.Config.Extra;
      };
    };
  };
}

const Banner = observer(() => {
  const { AppStore } = useRootStore();

  return AppStore?.version?.env !== 'production' ? (
    <div
      style={{
        width: '100%',
        background: getCSS('--color-warn'),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <span
        style={{
          fontSize: '0.75rem',
          padding: '0.31rem',
          fontWeight: 'bold',
        }}
      >
        Environnement: {`${AppStore?.version?.env}`.toUpperCase()}
      </span>
    </div>
  ) : null;
});

export const Main = observer(
  ({
    handleEvent: { selected },
    config: { navigation, header } = {},
  }: MainType.Props) => {
    const notifications = useNotification();
    const { breakpoint, maxResolution } = useDevice();
    const { t } = useTranslation();
    const { AppStore, MenuStore, WizardStore } = useRootStore();

    const menu = MenuStore.menu;

    const Wizard = useMemo(
      () => WizardStore.selected && WizardStore.selected.wizard.component,
      [WizardStore.selected],
    );

    return (
      <main className="main">
        {!AppStore.isResponsive && maxResolution && (
          <div className="main__mask">
            <Write
              data={{ item: t('watermelon_RESOLUTION_TITLE') }}
              config={{ mode: 'title-small' }}
            ></Write>
            <Write
              data={{ item: t('watermelon_RESOLUTION_DESCRIPTION') }}
              config={{ mode: 'title-small-description' }}
            ></Write>
          </div>
        )}
        {notifications}
        <Debug config={{ active: !!AppStore.debug }} />
        <div className="main__header">
          <Banner />
          {<Header config={{ ...header }} />}
        </div>
        <div className="main__contain">
          <div className="main__contain__items">
            <div
              id="elevate-container"
              className="main__contain__items__elevate"
            >
              {Wizard && (
                <Elevate
                  handleEvent={{
                    exit: () => {
                      WizardStore.clear();
                    },
                  }}
                >
                  <Wizard />
                </Elevate>
              )}
            </div>
            <div className="main__contain__items__nav">
              {navigation &&
                menu.length > 0 &&
                (breakpoint.navigation === 'desktop' ? (
                  <NavDesktop
                    handleEvent={{
                      navigation: (path) => selected(path),
                    }}
                    data={menu}
                    config={{ mode: navigation?.mode, logo: navigation?.logo }}
                  />
                ) : (
                  <NavMobileTop
                    handleEvent={{
                      navigation: (path) => selected(path),
                    }}
                    data={menu}
                  />
                ))}
            </div>
            <div className="main__contain__items__page">
              <Outlet />
            </div>
          </div>
        </div>
      </main>
    );
  },
);
