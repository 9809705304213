import { action, makeAutoObservable, observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useRootStore } from '../hook/useRootStore.hook';
import { RootStoreMobX } from './root.store';
import { logWithState } from '../functions/console.function';

export type NotificationModeType =
  | 'ERROR'
  | 'WARNING'
  | 'SUCCESS'
  | 'INFO'
  | 'LOADING';
export type NotificationContentType = string;

export type NotificationStoreContextType = {
  notif: {
    mode: NotificationModeType;
    content: NotificationContentType;
  };
};

export class NotificationStoreMobX {
  rootStore: RootStoreMobX;
  @observable public load: boolean = false;
  @observable public notif: NotificationStoreContextType['notif'] | null = null;

  constructor(rootStore: RootStoreMobX) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  @action public init() {
    this.load = true;
  }

  @action public emit({
    mode,
    content,
  }: {
    mode: NotificationModeType;
    content: NotificationContentType;
  }) {
    if (this.load) {
      this.notif = {
        mode,
        content,
      };
    } else {
      this.notif = null;
    }
  }
}

export const ConfigNotification = observer(
  ({ children }: { children: React.ReactNode }) => {
    const { NotificationStore } = useRootStore();

    useEffect(() => {
      if (!NotificationStore.load) {
        logWithState({ state: 'INFO', value: 'ConfigNotification init' });
        NotificationStore.init();
      }

      if (NotificationStore.load) {
        logWithState({ state: 'INFO', value: 'ConfigNotification load' });
      }
    }, [NotificationStore.load]);

    return NotificationStore.load ? children : null;
  },
);
