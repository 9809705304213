import { action, makeAutoObservable, observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { buildFullSchemaGQL } from '../functions/request.function';
import { useRootStore } from '../hook/useRootStore.hook';
import { GQLContextType } from './gql.store';
import { RootStoreMobX } from './root.store';
import { logWithState } from '../functions/console.function';

type Me = any;

export type AuthContextType = {
  user: Me;
  account: { firstName: string; lastName: string; email: string };
  token: string;
  load: boolean;
  url: string;
  features: {
    tfa?: {
      label: string;
    };
    updatePassword?: boolean;
  };
};

export class AuthStoreMobX {
  rootStore: RootStoreMobX;
  @observable public user: AuthContextType['user'];
  @observable public token: AuthContextType['token'] | null = null;
  @observable public url: AuthContextType['url'] = window.location.pathname;
  @observable public features: AuthContextType['features'] | null = null;
  @observable public load: AuthContextType['load'] = false;
  @observable public account: AuthContextType['account'] | null = null;
  constructor(rootStore: RootStoreMobX) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  @action public async init({
    features,
  }: {
    features: AuthContextType['features'];
  }) {
    this.load = false;
    this.setFeatures(features);
    this.login();
    this.load = true;
  }

  @action public async setAccount(account: AuthContextType['account']) {
    this.account = account;
  }

  @action public login(token?: AuthContextType['token']) {
    if (token) {
      this.token = token;
      localStorage.setItem('token', token);
    } else {
      this.token = localStorage.getItem('token') || null;
    }
  }

  @action private setFeatures(value: AuthContextType['features']) {
    this.features = value;
  }

  @action public logout() {
    this.token = null;
    this.user = null;
    localStorage.removeItem('token');
  }

  @action public async setMe({
    client,
    introspection,
  }: {
    client: GQLContextType['client'];
    introspection: GQLContextType['introspection'];
  }) {
    try {
      const { data: dataMe } = await client.query<{
        me: Me;
      }>({
        query: buildFullSchemaGQL({
          operationName: 'me',
          operationType: 'QUERY',
          introspection: introspection,
        }),
      });
      this.user = dataMe.me;
    } catch (e) {
      console.log({
        e,
        token: this.token,
        localStorage: localStorage.getItem('token'),
        introspection,
        client,
      });
      this.logout();
    }
  }
}

export const ConfigAuth = observer(
  ({
    children,
    features,
  }: { children: React.ReactNode } & {
    features: AuthContextType['features'];
  }) => {
    const { AuthStore, GQLStore } = useRootStore();

    useEffect(() => {
      if (!AuthStore.load) {
        logWithState({ state: 'INFO', value: 'ConfigAuth init' });

        (async () => {
          await AuthStore.init({
            features,
          });
        })();
      }

      if (AuthStore.load) {
        logWithState({ state: 'INFO', value: 'ConfigAuth load' });
      }
    }, [AuthStore.load]);

    useEffect(() => {
      if (AuthStore.token) {
        GQLStore.updateHeaders({
          'x-access-token': AuthStore.token || null,
        });
      }
    }, [AuthStore.token]);

    return AuthStore.load ? children : null;
  },
);
