import { useRootStore } from '@gimlite/watermelon';
import IEM from '@gimlite/watermelon/assets/logo/IEM_small';
import { Main } from '@gimlite/watermelon/components/main/main.component';
import { observer } from 'mobx-react-lite';
import LogoIEMPrestoUnity from './assets/logo/prestoUnity';

const MainUse = observer(() => {
  const { SSOStore } = useRootStore();

  return (
    <Main
      handleEvent={{
        selected: (key: string) => {},
      }}
      config={{
        navigation: {
          logo: LogoIEMPrestoUnity(),
          mode: 'drop',
        },
        //! Lors d'une refact du router, ce code apparaitra une fois en global (PROBLEM 1)
        //******* START ************/
        header: {
          logo: IEM(),
        },
        //******* STOP ************/
      }}
    />
  );
});

export default MainUse;
