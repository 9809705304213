import { action, makeAutoObservable, observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useRootStore } from '../hook/useRootStore.hook';
import { RootStoreMobX } from './root.store';
import { logWithState } from '../functions/console.function';

type WizardType = {
  id: string;
  component: React.ComponentType<any>;
};

type WizardStoreType = any;

export type WizardStoreContextType = {
  selected: { wizard: WizardType; store?: WizardStoreType };
  registry: Array<WizardType>;
};

export class WizardStoreMobX {
  rootStore: RootStoreMobX;
  @observable public load: boolean = false;
  @observable public selected: WizardStoreContextType['selected'] | null = null;
  @observable private registry: WizardStoreContextType['registry'] = [];

  constructor(rootStore: RootStoreMobX) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  @action public init({
    registry,
  }: {
    registry: WizardStoreContextType['registry'];
  }) {
    this.load = true;
    this.registry = registry;
  }

  @action public select({
    id,
    store,
  }: {
    id: WizardType['id'];
    store?: WizardStoreType['store'];
  }) {
    if (this.load) {
      const selectedWizard = this.registry.find((item) => item.id === id);

      if (!!selectedWizard) {
        this.selected = { wizard: selectedWizard, store };
      }
    }
  }

  @action public clear() {
    this.selected = null;
  }
}

export const ConfigWizard = observer(
  ({
    children,
    registry,
  }: {
    children: React.ReactNode;
    registry: WizardStoreContextType['registry'];
  }) => {
    const { WizardStore } = useRootStore();

    useEffect(() => {
      if (!WizardStore.load) {
        logWithState({ state: 'INFO', value: 'ConfigWizard init' });
        WizardStore.init({ registry });
      }

      if (WizardStore.load) {
        logWithState({ state: 'INFO', value: 'ConfigWizard load' });
      }
    }, [WizardStore.load]);

    return WizardStore.load ? children : null;
  },
);
