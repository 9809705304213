import { useRootStore, Write, Write2 } from '@gimlite/watermelon';
import { Page } from '@gimlite/watermelon/components/page/page.component';
import { observer } from 'mobx-react-lite';
import { Module } from '../../../components/module/module.component';
import './product-digital.page.scss';

export const ProductDigitalPage = observer(() => {
  const { SSOStore } = useRootStore();

  return (
    <Page className="productDigitalPage">
      <div className="productDigitalPage__contain">
        <Write2 config={{ mode: 'title-1', color: 'deep_indigo' }}>
          Modules de gestion centralisée du stationnement
        </Write2>
        <Write2 config={{ mode: 'title-3' }}>
          Choisissez un module pour commencer
        </Write2>
        <div className="productDigitalPage__contain__part__services">
          {(SSOStore.resources?.modules || []).length >= 1 ? (
            SSOStore.resources?.modules
              .map(
                ({ name, description, canUse, label, title, color, url }) => (
                  <Module
                    key={name}
                    config={{ isActive: canUse }}
                    data={{
                      label,
                      description,
                      color,
                      title,
                      url,
                    }}
                  ></Module>
                ),
              )
              .sort((a, b) => {
                if (a === b) return 0;
                return a ? -1 : 1;
              })
          ) : (
            <Write
              data={{ item: 'Aucun service ici' }}
              config={{ mode: 'label' }}
            ></Write>
          )}
        </div>
      </div>
    </Page>
  );
});
