import { message } from 'antd';
import { NoticeType } from 'antd/es/message/interface';
import { useEffect } from 'react';
import { NotificationModeType } from '../store/notification.store';
import { useRootStore } from './useRootStore.hook';

const mapMode: Record<NotificationModeType, NoticeType> = {
  ERROR: 'error',
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  LOADING: 'loading',
};

export const useNotification = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const { NotificationStore } = useRootStore();

  useEffect(() => {
    if (!NotificationStore.notif) return;
    const { mode, content } = NotificationStore.notif;

    messageApi.open({
      duration: 3,
      type: mapMode[mode],
      content,
      style: {
        textAlign: 'end',
        marginRight: '10px',
      },
    });
  }, [NotificationStore.notif]);

  return contextHolder;
};
